/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a55cbd90-70c2-42e7-bf88-64d1f88d9cae",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DJfcrNOSR",
    "aws_user_pools_web_client_id": "582fvvl8480mja71euso9vcbpu",
    "oauth": {},
    "aws_user_files_s3_bucket": "question-mapper-files154632-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
