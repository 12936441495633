import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
//import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const CHANGE_PASSWORD = "changePassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: getUser(),
  isAuthenticated: getUser()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      try {
        const user = Auth.signIn(credentials.username, credentials.password);
        if (user) {
          AmplifyEventBus.$emit("authState", "signedIn");
          context.commit(SET_AUTH, true);
          resolve(user);
        }
      } catch (err) {
        if (err.code === "UserNotConfirmedException") {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          context.commit(SET_ERROR, err.message);
        } else if (err.code === "PasswordResetRequiredException") {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          context.commit(SET_ERROR, err.message);
        } else if (err.code === "NotAuthorizedException") {
          // The error happens when the incorrect password is provided
          context.commit(SET_ERROR, err.message);
        } else if (err.code === "UserNotFoundException") {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          context.commit(SET_ERROR, err.message);
        } else {
          context.commit(SET_ERROR, err);
        }
      }
    });
  },
  [LOGOUT](context) {
    Auth.signOut()
      .then(() => {
        AmplifyEventBus.$emit("authState", "signedOut");
        context.commit(PURGE_AUTH);
      })
      .catch(err => {
        context.commit(SET_ERROR, err);
      });
  },
  [VERIFY_AUTH](context) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user && user.signInUserSession) {
          context.commit(SET_AUTH, user.username);
        } else {
          context.commit(PURGE_AUTH);
        }
      })
      .catch(err => {
        context.commit(SET_ERROR, err);
        context.commit(PURGE_AUTH);
      });
  },
  [CHANGE_PASSWORD](context, credentials) {
    return new Promise(resolve => {
      try {
        const result = Auth.completeNewPassword(
          credentials.userPromise,
          credentials.new_password
        );
        if (result) {
          resolve(result);
        }
      } catch (err) {
        context.commit(SET_ERROR, err);
      }
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    //JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    //JwtService.destroyToken();
  }
};

async function getUser() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.signInUserSession) {
      return user;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};
